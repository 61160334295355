export class MdlMembresiaContacto {
  public id: number = -1;
  public nombre: string ="";
  public id_clb_membresia: number = -1;
  public cargo: string = "";
  public departamento: string = "";
  public cumple: Date = new Date();
  public tel_movil: string = "";
  public tel_oficina: string = "";
  public tel_fax: string = "";
  public tel_casa: string = "";
  public tel_radio: string = "";
  public correo: string = "";
  public correo_alterno: string = "";
  public calle: string = "";
  public num_ext: string = "";
  public num_int: string = "";
  public colonia: string = "";
  public codigo: string = "";
  public id_municipio: number = -1;
  public observaciones: string = "";
  public clave: string = "";
  public empresa: string = "";
  public id_crm_contacto_tipo: number = -1;
  public id_rh_empleado: number = -1;
  public titular: boolean = false;
  public codigo_extra: string = "";
  public id_clb_membresia_contacto_estatus: number = -1;
  public casillero: string = "";
  public membresia: string = "";
  public rfid: string = "";
  public pais: string = "";
  public estado: string = "";
  public municipio: string = "";
  public estatus: string = "";

}
