import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrantesService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * Metodo para obtener la membresia del contacto con base en un Identificador o Numero
   * @param id Numero o Identificador de la membresia contacto
   * @returns Observable con membresia contacto
   */
  getMembresiaContacto(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/contacto/cargacontacto/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  public estatus(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/contacto/estatus`);
  }
}
