<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
    <!-- header -->
    <div class="navbar-header pl-2 pr-2 ml-3 text-center">
        <a class="navbar-brand m-0 w-100" href="#">
            <img src="./assets/img/logo-icon.png" width="35px">
            <span class="ml-1">Arcarius ERP</span>
        </a>
    </div>
    <!-- sidebar toggler -->
    <div class="sidebar-toggler ml-auto mr-3">
        <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>
    </div>
    <!-- right menu toggler -->
    <div class="nav-toggler-right mr-4 d-md-none">
        <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
            <img src="./assets/img/admin.png" class="img-fluid" alt="">
        </button>
        <span class="user-profile nav-alert bg-danger"></span>
    </div>
    <!-- left box -->
    <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
        <li class="nav-item navicon">
            <a class="btn nav-link" (click)="toggleSidebarPin()"><i class="fa fa-bars"></i></a>
        </li>

    </ul>

    <!-- right menu -->
    <div class="collapse navbar-collapse right-nav" id="collapseBasic" collapse="isCollapsed">
        <ul class="navbar-nav ">
            <li class="nav-item">
                <div class="btn-group">
                    <a class="btn nav-link" data-toggle="dropdown">
                        <i class="fas fa-user"></i>
                        <span class="link-text">Configuracion</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <button [routerLink]="['/escritorio', 'perfil']" class="dropdown-item" type="button">
                            <i class="fa fa-cogs" aria-hidden="true"></i>
                            Perfil
                        </button>
                        <button (click)="logout()" class="dropdown-item" type="button">
                            <i class="fas fa-sign-out-alt"></i>
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                            Salir
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>