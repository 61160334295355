import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EstadocuentaService } from './estadocuenta.service';

@Injectable({
  providedIn: 'root'
})
export class EstadocuentaResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private generalService: EstadocuentaService
    ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    // return this.generalService.getAdeudoTabla(1);
  }
}
