import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UsuarioService } from '../auth/services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private usuarioService: UsuarioService

  constructor(usuarioService: UsuarioService, private router: Router) {
    this.usuarioService = usuarioService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `${token}`
        }
      });
    } else {
      this.usuarioService.logout();
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 403) {
          //this.router.navigateByUrl('/login');
          //this.router.navigate(['login']);
          this.usuarioService.logout();
        }
        return throwError(err);
      })
    );
  }
}
