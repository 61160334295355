import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../auth/services/usuario.service';
import { MdlUsuario } from '../../auth/models/MdlUsuario';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public usuario: MdlUsuario;
  cargando = false;
  public data: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.usuario = JSON.parse(localStorage.getItem('usuario'));

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.cargando = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.cargando = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
  }

}
