<section id="wrapper" class="login-register login-sidebar" style="background-image:url(./../../../assets/img/regency.png); background-repeat: no-repeat;background-position: left; margin-top: -60px;">
    <div class="login-box card">
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
                <a href="javascript:void(0)" class="text-center db">
                    <img src="./../../../assets/img/logo-icon.png" />
                    <br />
                    <img src="./../../../assets/img/logo-text.png" alt="Home" /></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <input class="form-control" formControlName="user" type="text" placeholder="User">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" placeholder="Password" formControlName="password">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-success btn-rounded" type="submit">
                            Acceso <i class="fas fa-sign-in-alt"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>